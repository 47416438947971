@keyframes vscomp-animation-spin {
  to {
    transform: rotateZ(360deg);
  }
}

.vscomp-popup-active {
  overflow: hidden !important;
}

.vscomp-ele {
  max-width: 250px;
  width: 100%;
  display: inline-block;
}

.vscomp-wrapper {
  color: #333;
  text-align: left;
  width: 100%;
  flex-wrap: wrap;
  font-family: sans-serif;
  font-size: 14px;
  display: inline-flex;
  position: relative;
}

.vscomp-wrapper *, .vscomp-wrapper :before, .vscomp-wrapper :after {
  box-sizing: border-box;
}

.vscomp-wrapper:focus {
  outline: none;
}

.vscomp-dropbox-wrapper {
  position: absolute;
  top: 0;
  left: 0;
}

.vscomp-toggle-button {
  cursor: pointer;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  align-items: center;
  padding: 7px 30px 7px 10px;
  display: flex;
  position: relative;
}

.vscomp-value {
  height: 20px;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 20px;
  overflow: hidden;
}

.vscomp-arrow {
  height: 100%;
  width: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.vscomp-arrow:after {
  content: "";
  height: 8px;
  width: 8px;
  border: 1px solid #111;
  border-color: #0000 #111 #111 #0000;
  margin-top: -6px;
  transform: rotate(45deg);
}

.vscomp-clear-icon {
  height: 12px;
  width: 12px;
  position: relative;
}

.vscomp-clear-icon:before, .vscomp-clear-icon:after {
  content: "";
  height: 12px;
  width: 2px;
  background-color: #999;
  position: absolute;
  top: 0;
  left: 5px;
}

.vscomp-clear-icon:before {
  transform: rotate(45deg);
}

.vscomp-clear-icon:after {
  transform: rotate(-45deg);
}

.vscomp-clear-icon:hover:before, .vscomp-clear-icon:hover:after {
  background: #333;
}

.vscomp-clear-button {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-top: -12px;
  display: none;
  position: absolute;
  top: 50%;
  right: 30px;
}

.vscomp-clear-button:hover {
  background: #ccc;
}

.vscomp-clear-button:hover .vscomp-clear-icon:before, .vscomp-clear-button:hover .vscomp-clear-icon:after {
  background-color: #333;
}

.vscomp-dropbox-close-button {
  cursor: pointer;
  height: 40px;
  width: 40px;
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-left: -20px;
  display: none;
  position: absolute;
  bottom: -48px;
  left: 50%;
}

.vscomp-value-tag.more-value-count {
  white-space: nowrap;
}

.vscomp-dropbox-container {
  width: 100%;
  z-index: 2;
}

.vscomp-dropbox {
  width: 100%;
  background-color: #fff;
}

.vscomp-options-container {
  max-height: 210px;
  position: relative;
  overflow: auto;
}

.vscomp-options-bottom-freezer {
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.vscomp-option {
  cursor: pointer;
  height: 40px;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 15px;
  display: flex;
  position: relative;
}

.vscomp-option.selected {
  background-color: #eee;
}

.vscomp-option.focused {
  background-color: #ccc;
}

.vscomp-option.disabled {
  cursor: default;
  opacity: .5;
}

.vscomp-option.group-title .vscomp-option-text {
  cursor: default;
  opacity: .6;
}

.vscomp-option.group-title.selected {
  background-color: #0000;
}

.vscomp-option.group-option {
  padding-left: 30px;
}

.vscomp-new-option-icon {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 0;
  right: 0;
}

.vscomp-new-option-icon:before {
  content: "";
  border: 15px solid #512da8;
  border-color: #512da8 #512da8 #0000 #0000;
  position: absolute;
  top: 0;
  right: 0;
}

.vscomp-new-option-icon:after {
  color: #fff;
  content: "+";
  height: 15px;
  width: 15px;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  display: flex;
  position: absolute;
  top: 0;
  right: 1px;
}

.vscomp-option-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  overflow: hidden;
}

.vscomp-option-description {
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #666;
  width: 100%;
  font-size: 13px;
  line-height: 15px;
  overflow: hidden;
}

.vscomp-search-container {
  height: 40px;
  border-bottom: 1px solid #ddd;
  align-items: center;
  padding: 0 5px 0 15px;
  display: flex;
  position: relative;
}

.vscomp-search-input {
  color: inherit;
  height: 38px;
  width: calc(100% - 30px);
  background-color: #0000;
  border: 0;
  padding: 10px 0;
  font-size: 15px;
}

.vscomp-search-input:focus {
  outline: none;
}

.vscomp-search-clear {
  color: #999;
  cursor: pointer;
  height: 30px;
  -webkit-user-select: none;
  user-select: none;
  visibility: hidden;
  width: 30px;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  display: flex;
}

.vscomp-search-clear:hover {
  color: inherit;
}

.vscomp-no-options, .vscomp-no-search-results {
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  display: none;
}

.vscomp-options-loader {
  text-align: center;
  padding: 20px 0;
  display: none;
}

.vscomp-options-loader:before {
  content: "";
  height: 40px;
  opacity: .7;
  width: 40px;
  background-color: #fff;
  border-radius: 50%;
  animation: vscomp-animation-spin .8s linear infinite;
  display: inline-block;
  box-shadow: -4px -5px 3px -3px #0000004d;
}

.vscomp-ele[disabled] {
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
}

.vscomp-ele[disabled] .vscomp-wrapper {
  opacity: .7;
  pointer-events: none;
}

.vscomp-wrapper .checkbox-icon {
  height: 15px;
  width: 15px;
  margin-right: 10px;
  display: inline-flex;
  position: relative;
}

.vscomp-wrapper .checkbox-icon:after {
  content: "";
  height: 100%;
  width: 100%;
  border: 2px solid #888;
  transition-duration: .2s;
  display: inline-block;
}

.vscomp-wrapper .checkbox-icon.checked:after {
  width: 50%;
  border-color: #0000 #512da8 #512da8 #0000;
  transform: rotate(45deg)translate(1px, -4px);
}

.vscomp-wrapper.show-as-popup .vscomp-dropbox-container {
  height: 100vh;
  opacity: 1;
  width: 100vw;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.vscomp-wrapper.show-as-popup .vscomp-dropbox {
  max-height: calc(80% - 48px);
  max-width: 500px;
  width: 80%;
  margin-top: -24px;
  position: relative;
}

.vscomp-wrapper.show-as-popup .vscomp-dropbox-close-button {
  display: flex;
}

.vscomp-wrapper.popup-position-left .vscomp-dropbox-container {
  justify-content: flex-start;
}

.vscomp-wrapper.popup-position-right .vscomp-dropbox-container {
  justify-content: flex-end;
}

.vscomp-wrapper.has-select-all .vscomp-toggle-all-button {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.vscomp-wrapper.has-select-all .vscomp-search-input, .vscomp-wrapper.has-select-all .vscomp-toggle-all-label {
  width: calc(100% - 55px);
}

.vscomp-wrapper.has-select-all .vscomp-toggle-all-label {
  display: none;
}

.vscomp-wrapper:not(.has-search-input) .vscomp-toggle-all-button {
  width: 100%;
}

.vscomp-wrapper:not(.has-search-input) .vscomp-toggle-all-label {
  display: inline-block;
}

.vscomp-wrapper.multiple .vscomp-option .vscomp-option-text {
  width: calc(100% - 25px);
}

.vscomp-wrapper.multiple .vscomp-option .vscomp-option-description {
  padding-left: 25px;
}

.vscomp-wrapper.multiple .vscomp-option.selected .checkbox-icon:after {
  width: 50%;
  border-color: #0000 #512da8 #512da8 #0000;
  transform: rotate(45deg)translate(1px, -4px);
}

.vscomp-wrapper.focused .vscomp-toggle-button, .vscomp-wrapper:focus .vscomp-toggle-button {
  box-shadow: 0 2px 2px #00000024, 0 3px 1px -2px #0000001f, 0 1px 5px #0003;
}

.vscomp-wrapper.closed .vscomp-dropbox-container, .vscomp-wrapper.closed.vscomp-dropbox-wrapper {
  display: none;
}

.vscomp-wrapper:not(.has-value) .vscomp-value {
  opacity: .5;
}

.vscomp-wrapper.has-clear-button.has-value .vscomp-clear-button {
  display: flex;
}

.vscomp-wrapper.has-clear-button .vscomp-toggle-button {
  padding-right: 54px;
}

.vscomp-wrapper.has-no-options .vscomp-options-container, .vscomp-wrapper.has-no-search-results .vscomp-options-container {
  display: none;
}

.vscomp-wrapper.has-no-options .vscomp-no-options, .vscomp-wrapper.has-no-search-results .vscomp-no-search-results {
  display: flex;
}

.vscomp-wrapper.has-search-value .vscomp-search-clear {
  visibility: visible;
}

.vscomp-wrapper.has-no-options .vscomp-toggle-all-button {
  opacity: .5;
  pointer-events: none;
}

.vscomp-wrapper.keep-always-open .vscomp-toggle-button {
  padding-right: 24px;
}

.vscomp-wrapper.keep-always-open .vscomp-clear-button {
  right: 5px;
}

.vscomp-wrapper.keep-always-open .vscomp-arrow {
  display: none;
}

.vscomp-wrapper.keep-always-open .vscomp-dropbox-container {
  z-index: 1;
  position: relative;
}

.vscomp-wrapper.keep-always-open .vscomp-dropbox {
  box-shadow: none;
  border: 1px solid #ddd;
  transition-duration: 0s;
}

.vscomp-wrapper.keep-always-open.focused, .vscomp-wrapper.keep-always-open:focus, .vscomp-wrapper.keep-always-open:hover {
  box-shadow: 0 2px 2px #00000024, 0 3px 1px -2px #0000001f, 0 1px 5px #0003;
}

.vscomp-wrapper.server-searching .vscomp-options-list {
  display: none;
}

.vscomp-wrapper.server-searching .vscomp-options-loader {
  display: block;
}

.vscomp-wrapper.has-error .vscomp-toggle-button {
  border-color: #b00020;
}

.vscomp-wrapper.show-value-as-tags .vscomp-toggle-button {
  padding: 4px 22px 0 10px;
}

.vscomp-wrapper.show-value-as-tags .vscomp-value {
  height: auto;
  min-height: 28px;
  text-overflow: unset;
  white-space: normal;
  flex-wrap: wrap;
  display: flex;
  overflow: auto;
}

.vscomp-wrapper.show-value-as-tags .vscomp-value-tag {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  border: 1px solid #ddd;
  border-radius: 20px;
  align-items: center;
  margin: 0 4px 4px 0;
  padding: 2px 3px 2px 8px;
  font-size: 12px;
  line-height: 16px;
  display: inline-flex;
  overflow: hidden;
}

.vscomp-wrapper.show-value-as-tags .vscomp-value-tag.more-value-count {
  padding-right: 8px;
}

.vscomp-wrapper.show-value-as-tags .vscomp-value-tag-content {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 20px);
  overflow: hidden;
}

.vscomp-wrapper.show-value-as-tags .vscomp-value-tag-clear-button {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.vscomp-wrapper.show-value-as-tags .vscomp-value-tag-clear-button .vscomp-clear-icon {
  transform: scale(.8);
}

.vscomp-wrapper.show-value-as-tags .vscomp-arrow {
  height: 34px;
}

.vscomp-wrapper.show-value-as-tags .vscomp-clear-button {
  margin-top: 0;
  top: 5px;
}

.vscomp-wrapper.show-value-as-tags.has-value .vscomp-arrow {
  display: none;
}

.vscomp-wrapper.show-value-as-tags.has-value .vscomp-clear-button {
  right: 2px;
}

.vscomp-wrapper.show-value-as-tags:not(.has-value) .vscomp-toggle-button {
  padding-bottom: 2px;
}

.vscomp-wrapper.show-value-as-tags:not(.has-value) .vscomp-value {
  align-items: center;
  padding-bottom: 3px;
}

.vscomp-wrapper.text-direction-rtl {
  direction: rtl;
}

.vscomp-wrapper.text-direction-rtl .vscomp-toggle-button {
  padding: 7px 10px 7px 30px;
}

.vscomp-wrapper.text-direction-rtl .vscomp-arrow {
  left: 0;
  right: auto;
}

.vscomp-wrapper.text-direction-rtl .vscomp-clear-button {
  left: 30px;
  right: auto !important;
}

.vscomp-wrapper.text-direction-rtl .checkbox-icon {
  margin-left: 10px;
  margin-right: 0;
}

.vscomp-wrapper.text-direction-rtl .checkbox-icon.checked:after {
  transform: rotate(45deg)translate(-4px, 1px);
}

.vscomp-wrapper.text-direction-rtl .vscomp-search-container {
  padding: 0 15px 0 5px;
}

.vscomp-wrapper.text-direction-rtl .vscomp-toggle-all-label, .vscomp-wrapper.text-direction-rtl .vscomp-option {
  text-align: right;
}

.vscomp-wrapper.text-direction-rtl .vscomp-option.group-option {
  padding-right: 30px;
}

.vscomp-wrapper.text-direction-rtl .vscomp-new-option-icon {
  left: 0;
  right: auto;
}

.vscomp-wrapper.text-direction-rtl .vscomp-new-option-icon:before {
  border-left-color: #512da8;
  border-right-color: #0000;
}

.vscomp-wrapper.text-direction-rtl .vscomp-new-option-icon:after {
  left: 1px;
  right: auto;
}

.vscomp-wrapper.text-direction-rtl.multiple .vscomp-option.selected .checkbox-icon:after {
  transform: rotate(45deg)translate(-4px, 1px);
}

.vscomp-wrapper.text-direction-rtl.has-clear-button .vscomp-toggle-button {
  padding-left: 54px;
}

.vscomp-wrapper.text-direction-rtl.keep-always-open .vscomp-toggle-button {
  padding-left: 24px;
}

.vscomp-wrapper.text-direction-rtl.keep-always-open .vscomp-clear-button {
  left: 5px;
}

.vscomp-wrapper.text-direction-rtl.show-value-as-tags .vscomp-toggle-button {
  padding: 4px 10px 0 22px;
}

.vscomp-wrapper.text-direction-rtl.show-value-as-tags .vscomp-value-tag {
  margin: 0 0 4px 4px;
  padding: 2px 8px 2px 3px;
}

.vscomp-wrapper.text-direction-rtl.show-value-as-tags .vscomp-value-tag.more-value-count {
  padding-left: 8px;
}

.vscomp-wrapper.text-direction-rtl.show-value-as-tags.has-value .vscomp-clear-button {
  left: 2px;
}

.pop-comp-wrapper {
  opacity: 0;
  color: #000;
  text-align: left;
  z-index: 1;
  background-color: #fff;
  flex-wrap: wrap;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2px 2px #00000024, 0 3px 1px -2px #0000001f, 0 1px 5px #0003;
}

.pop-comp-arrow {
  z-index: 1;
  width: 16px;
  height: 16px;
  position: absolute;
  overflow: hidden;
}

.pop-comp-arrow:before {
  content: "";
  width: 16px;
  height: 16px;
  transform-origin: 0 0;
  background-color: #fff;
  position: absolute;
  top: 8px;
  left: 8px;
  transform: rotate(45deg);
  box-shadow: 0 2px 2px #00000024, 0 3px 1px -2px #0000001f, 0 1px 5px #0003;
}

.pop-comp-content {
  z-index: 2;
  position: relative;
}

.pop-comp-wrapper.position-bottom .pop-comp-arrow {
  margin-left: -8px;
  top: -15px;
  left: 0;
}

.pop-comp-wrapper.position-bottom .pop-comp-arrow:before {
  box-shadow: 0 0 2px #00000024;
}

.pop-comp-wrapper.position-top .pop-comp-arrow {
  margin-left: -8px;
  bottom: -15px;
  left: 0;
}

.pop-comp-wrapper.position-right .pop-comp-arrow {
  margin-top: -8px;
  top: 0;
  left: -15px;
}

.pop-comp-wrapper.position-left .pop-comp-arrow {
  margin-top: -8px;
  top: 0;
  right: -15px;
}

.pop-comp-disable-events {
  pointer-events: none;
}

/*# sourceMappingURL=index.8d156729.css.map */
